<template>
	<div>
		<Header title="批次号详情" @back="onBack"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" @finish="onSearch">
				<a-row>
					<a-form-item label="卖品劵号" name="barcode" class="ui-form__item">
						<a-input v-model:value="formState.barcode" placeholder="请输入卖品劵号"></a-input>
					</a-form-item>
					
					<a-form-item label="绑定状态" name="isBind" class="ui-form__item">
						<a-select v-model:value="formState.isBind" style="width: 180px;" placeholder="请选择绑定状态">
							<a-select-option :value="1">已绑定</a-select-option>
							<a-select-option :value="0">未绑定</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="是否启用" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" style="width: 180px;" placeholder="请选择绑定状态">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onBatchBind">批量绑定</a-button>
						<a-button v-permission="['coupon_snack_list_open']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onShowTaskModal('enableSaleCoupon')">批量启用</a-button>
						<a-button v-permission="['coupon_snack_list_disabled']" type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onShowTaskModal('disableSaleCoupon')">批量禁用</a-button>
						<span v-permission="['coupon_snack_record_export']">
							<exportComponent type="snackCoupon" :searchData="searchData"></exportComponent>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :rowSelection="{ type: 'checkbox', getCheckboxProps: getCheckboxProps, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 2000 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
						</template>
						<template v-if="column.key === 'barcode'">
							<span :id="'copySnackCode' + index">{{ record.barcode }}</span>
						</template>
						
						<template v-if="column.key === 'status'">
							<span>{{ record.isBind === 1 ? '已绑定' : '未绑定' }}</span>
						</template>
						<template v-if="column.key === 'isIssue'">
							<span>{{ record.isIssue === 1 ? '已发放' : '未发放' }}</span>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'isGrant'">
							{{ record.isGrant ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>禁用</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_bind']" v-if="record.isBind !== 1 && !record.isGrant" @click="onBind(record)">
											<a-menu-item>
												绑定
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_issue']" v-if="record.isIssue !== 1 && !record.isGrant" @click="onUpdateIssue(record, index)">
											<a-menu-item>
												发放
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_list_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			<div style="text-align: center;">
				<a-button @click="onBack">返回</a-button>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="visible" destroyOnClose title="已选" width="850px" :zIndex="8" @ok="onBindOk">
			<!-- <a-button style="margin-bottom: 20px;" @click="onSetVisible">输入会员账号</a-button> -->
			<a-spin :spinning="loading">
				<a-form ref="setMemberForm" name="setMemberForm" :model="memberModelRef">
					<a-form-item label="会员手机号" :rules="[{required: true, message: '请输入会员手机号'}]">
						<a-input v-model:value="memberModelRef.phone" placeholder="请输入会员手机号"/>
					</a-form-item>
				</a-form>
				
				<a-table :dataSource="selectedListData" :columns="selectedColumns" :pagination="false">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
						</template>
						<template v-if="column.key === 'status'">
							<span>{{ record.isBind === 1 ? '已绑定' : '未绑定' }}</span>
						</template>
					</template>
				</a-table>
			</a-spin>
		</a-modal>
		
		<a-modal v-model:visible="setVisible" destroyOnClose width="700px" title="绑定会员" @ok="onMemberOk">
			<a-form ref="setMemberForm" name="setMemberForm" :model="memberModelRef" :labelCol="{span: 6}" :wrapperCol="{span: 14}">
				<a-form-item v-if="!visible" label="卖品券号">
					{{ bindItem.barcode }}
				</a-form-item>
				
				<a-form-item v-if="!visible" label="批次号">
					{{ bindItem.batchNo }}
				</a-form-item>
				
				<a-form-item v-if="!visible" label="有效期">
					{{ transDateTime(bindItem.receiveStartTime, 1) }} - {{ transDateTime(bindItem.receiveEndTime, 1) }}
				</a-form-item>
				
				<a-form-item label="会员手机号" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="memberModelRef.phone" placeholder="请输入会员手机号"/>
				</a-form-item>
			</a-form>
		</a-modal>
		
		<a-modal v-model:visible="seeVisible" title="查看" width="750px">
			<a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
				<a-form-item label="卖品券号">{{ detail.barcode }}</a-form-item>
				<a-form-item label="批次号">{{ detail.batchNo }}</a-form-item>
				<a-form-item label="有效期">
					{{ transDateTime(detail.receiveStartTime, 1) }} - {{ transDateTime(detail.receiveEndTime, 1) }}
				</a-form-item>
				<a-form-item label="所属影院">
					{{ detail.cinemaName }}
				</a-form-item>
				<a-form-item label="使用规则">
					{{ detail.useRule }}
				</a-form-item>
				<a-form-item label="可兑换卖品">
					只可兑换其中一件
					<a-table :columns="snackClumns" :dataSource="snackList" :pagination="false">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'img'">
								<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
							</template>
							
							<template v-if="column.key === 'card'">
								<div v-if="record.isCard">
									<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
									<div>卡次数：{{ record.cardBalanceTimes }}</div>
									<div>卡等级：{{ record.cardTitle || '' }}</div>
								</div>
								<div v-else>--</div>
							</template>
						</template>
					</a-table>
				</a-form-item>
			</a-form>
		</a-modal>
		
		<a-modal v-model:visible="taskVisible" title="创建批量操作任务" @ok="onCreateTaskDelayOk" :zIndex="10" destroyOnClose>
			<a-spin :spinning="loading">
				<a-form>
					<a-form-item label="任务名称">
						<a-input v-model:value="taskTitle" placeholder="请输入"/>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import { getExchangeList, updateIssue, bindVoucher, batchBindVoucher, updateIsDisabled, delCoupon, couponBatchDetail, asyncBatchEnable, asyncBatchDisable } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			Header,
			exportComponent
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				formState: {
					couponType: 3,
				},
				searchData: {},
				detail: {},
				seeVisible: false,
				visible: false,
				setVisible: false,
				selectedRowKeys: [],
				selectedListData: [],
				selectedColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '绑定状态',
					key: 'status'
				}],
				bindItem: {},
				memberModelRef: {
					member: ''
				},
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '卖品券号',
					key: 'barcode',
					dataIndex: 'barcode'
				}, {
					title: '有效期',
					key: 'time'
				},{
					title: '绑定状态',
					key: 'status',
					width: 130
				}, {
					title: '发放状态',
					key: 'isIssue',
					width: 130
				}, {
					title: '是否启用',
					key: 'isDisabled',
					width: 130
				}, {
					title: '是否商品卖品发放',
					key: 'isGrant'
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 160
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				snackList: [],
				snackClumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'img'
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '销售价',
					dataIndex: 'nowPrice'
				}],
				taskVisible: false,
				taskTitle: '',
				taskType: ''
			}
		},
		created() {
			this.onSearch();
			if (this.id) {
				this.getDetail();
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.voucherImportId = this.id;
				this.searchData.couponType = 3;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getExchangeList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getDetail() {
				try {
					let ret = await couponBatchDetail({
						id: this.id
					});
					if (ret.code === 200) {
						this.snackList = ret.data.storeGoodsStocks;
					}
				} catch(e) {
					
				}
			},
			async onUpdateIssue(item, index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copySnackCode" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
				item.isIssue = 1;
				// this.loading = true;
				let ret = await updateIssue({
					id: item.id
				});
				// this.loading = false;
				// if (ret.code === 200) {
					
				// }
			},
			onSee(item) {
				this.seeVisible = true;
				this.detail = item;
			},
			getCheckboxProps(record) {
				return { disabled: record.status === 1 }
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onBatchDisabled() {
				this.$confirm({
					title: "提示",
					content: '确定批量禁用选择的卖品券吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await asyncBatchDisable({
								ids: this.selectedRowKeys.join(','),
								type: this.taskType,
								title: this.taskTitle
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.taskVisible = false;
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onBatchOpen() {
				this.$confirm({
					title: "提示",
					content: '确定批量启用选择的卖品券吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await asyncBatchEnable({
								ids: this.selectedRowKeys.join(','),
								type: this.taskType,
								title: this.taskTitle
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.taskVisible = false;
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onBatchBind() {
				let canBatchBind = true;
				for (let i = 0; i < this.selectedListData.length;i++) {
					if (this.selectedListData[i].isBind || this.selectedListData[i].isGrant) {
						canBatchBind = false;
						break;
					}
				}
				if (!canBatchBind) {
					return this.$message.warn('已选择的卖品券中含有【已绑定】或【用于商品卖品发放】的卖品券，无法进行批量绑定操作');
				}
				this.memberModelRef = {};
				this.visible = true;
			},
			onBind(item) {
				this.memberModelRef = {};
				this.bindItem = JSON.parse(JSON.stringify(item));
				this.setVisible = true;
			},
			onMemberOk() {
				//  单一绑定
				this.$refs.setMemberForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await bindVoucher({
							id: this.bindItem.id,
							phone: this.memberModelRef.phone
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('绑定成功');
							this.memberModelRef = {};
							this.setVisible = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async onBindOk() {
				// 批量绑定
				this.$refs.setMemberForm.validateFields().then(async () => {
					this.taskType = 'bindCoupon';
					this.taskTitle = '';
					this.taskVisible = true;
				})
			},
			onBatchBindCoupon() {
				this.$confirm({
					title: '提示',
					content: '确定批量绑定选择的卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await batchBindVoucher({
								ids: this.selectedRowKeys.join(','),
								phone: this.memberModelRef.phone,
								title: this.taskTitle,
								type: 'snackBindUserCoupon'
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('批量绑定任务创建成功，已选中的兑换券将在2-3分钟后完成绑定');
								this.memberModelRef = {};
								this.visible = false;
								this.taskVisible = false;
								this.taskTitle = '';
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该卖品券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updateIsDisabled({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loadig = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该卖品券吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await delCoupon({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
							console.log(e)
						}
					}
				})
			},
			onShowTaskModal(type) {
				this.taskType = type;
				this.taskTitle = '';
				this.taskVisible = true;
			},
			async onCreateTaskDelayOk() {
				// 执行批量延期
				if (!this.taskTitle.trim()) {
					this.$message.warn('请输入任务名称');
			        return;
				}
				if (this.taskType === 'enableSaleCoupon') {
					// 批量启用
					this.onBatchOpen();
				}
				if (this.taskType === 'disableSaleCoupon') {
					// 批量禁用
					this.onBatchDisabled();
				}
				if (this.taskType === 'bindCoupon') {
					// 批量绑定
					this.onBatchBindCoupon();
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
